<template>
  <div></div>
</template>

<script>
export default {
  name: "LoginView",
  mounted() {
    this.$auth.loginWithRedirect();
  }
};
</script>
